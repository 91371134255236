






















import Vue from 'vue';
import { computed } from 'vue-demi';
import { useStore } from '../../store';
export default Vue.extend({
  props: {
    item: {
      type: Object,
    },
  },

  setup() {
    const mainStore = useStore();

    const hasRole = computed(() => {
      return mainStore.hasRoles(['admin', 'operator']);
    });

    return {
      hasRole,
    };
  },
});
