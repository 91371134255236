








































































































































































































import { defineComponent, ref, reactive, watch, PropType, toRaw, computed } from 'vue-demi';
import { Part, PartSaveBody } from '@project/shared';
import { partsDb, PartsFilters } from '../PartsDb';
import { debouncedWatch, asyncComputed } from '@vueuse/core';
import { useCartStore } from '../cartStore';
import { useStore } from '@/store';
import { usePartsStore } from '../partsStore';
import FileUpload from '@/components/FileUpload.vue';
import RequireOnline from '@/components/RequireOnline.vue';
import Confirm from '@/components/Confirm.vue';
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
import categories from '../partsCategories.json';
import PartsTableAmos from './PartsTableAmos.vue';
import PartsTableDoc from './PartsTableDoc.vue';
import PartsTableInfos from './PartsTableInfos.vue';
import PartsTableEditMenu from './PartsTableEditMenu.vue';
import PartsTableCart from './PartsTableCart.vue';

type PartWithDocUrl = Part & {
  _docUrl?: string;
};

export default defineComponent({
  components: {
    FileUpload,
    RequireOnline,
    Confirm,
    PartsTableAmos,
    PartsTableDoc,
    PartsTableInfos,
    PartsTableEditMenu,
    PartsTableCart,
  },

  props: {
    filters: {
      type: Object as PropType<PartsFilters>,
      default: () => ({}),
    },
  },

  setup(props, { root }) {
    const mainStore = useStore();
    const cartStore = useCartStore();
    const parts = usePartsStore();

    const headers = ref([
      {
        text: 'Code AMOS',
        value: 'codeAmos',
        width: '100px',
      },
      {
        text: 'Qty',
        value: 'quantity',
        width: '50px',
      },
      {
        text: 'Desc',
        value: 'description',
        width: '100%',
      },
      {
        text: 'Doc',
        value: 'documentation',
        width: '50px',
      },
      {
        text: '',
        value: 'actions',
        align: 'end',
        width: '220px',
      },
    ]);

    const gaPart = ref<Part>();
    const gaCode = computed(
      () => gaPart.value?.subLevelGa || gaPart.value?.ga || gaPart.value?.refManufacturer || '',
    );
    const items = ref<PartWithDocUrl[]>([]);

    const searchValue = ref('');
    const query = reactive({
      search: '',
      family: '',
    });

    watch(
      () => root.$route.params,
      () => {
        searchValue.value = query.search = query.family = '';
      },
      { deep: true },
    );

    debouncedWatch(
      searchValue,
      () => {
        query.search = searchValue.value || '';
      },
      { debounce: 400 },
    );

    const filteredItems = computed(() => {
      const search = query.search.toLowerCase().trim();

      return items.value
        .filter((item) => {
          if (!query.family) return true;
          return item.family === query.family;
        })
        .filter((item) => {
          if (!search) return true;
          return (
            item.codeAmos?.toLowerCase().includes(search) ||
            item.description?.toLowerCase().includes(search)
          );
        });
    });

    async function updateItems() {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      gaPart.value = await parts.getByCodeAmos(props.filters.parent!);

      if (gaPart.value) {
        items.value = (await parts.search(query.search, props.filters)).filter(
          (item) => item.codeAmos !== props.filters.parent,
        );
      } else {
        items.value = [];
      }
    }

    watch(query, updateItems, { deep: true });
    watch(props, updateItems, { deep: true });

    updateItems().catch(console.error);

    function itemClass(item: Part) {
      return cartStore.isInCart(item.codeAmos) ? 'is-in-cart' : '';
    }

    /**
     * Edit stuff
     */
    const isNew = ref(false);
    const newIsSubLevel = ref(false);
    const isExisting = ref(false);
    const showEditDialog = ref(false);
    const loading = ref(false);
    const formRef = ref<{
      validate(): void;
    } | null>(null);

    const newItem = (codeAmos = '') => ({
      codeAmos,
      ga: '',
      description: '',
      quantity: 1,
      family: '',
      refManufacturer: '',
      supplier: '',
      mainAssy: '',
      subLevelGa: '',
      children: [],
      visible: false,
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const values = reactive<PartSaveBody>(newItem());

    const validForm = ref(false);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function copyFields(src: any, dest: any) {
      for (const key in dest) {
        dest[key] = src[key];
      }
    }

    // New item
    const codeAmos = ref();
    const searchExisting = ref('');
    const existingItems = asyncComputed(async () => {
      return (await partsDb.search(searchExisting.value)).slice(0, 50).map((item) => ({
        text: item.codeAmos,
        value: item,
      }));
    }, []);

    const searchChild = ref('');
    const partChildren = ref<Part[]>([]);
    const childrenItems = asyncComputed(async () => {
      const results = [
        ...(partChildren.value || []),
        ...(await partsDb.search(searchChild.value, { noSubLevel: true })).slice(0, 50),
      ];

      return results.map((item) => ({
        text: item.codeAmos,
        value: item,
      }));
    }, []);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function onChooseAmos(value: string | any) {
      if (typeof value === 'string') {
        // New AMOS
        copyFields(newItem(value), values);
        values.ga = gaPart.value?.subLevelGa || gaPart.value?.ga;
        partChildren.value = [];

        isExisting.value = false;
      } else {
        // Existing
        copyFields(value.value, values);

        values.quantity = 1;
        const inView = items.value.find((item) => item.codeAmos === values.codeAmos);
        if (inView) {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          values.quantity = inView.gaItems![gaCode.value];
        } else {
          values.ga = gaPart.value?.subLevelGa || gaPart.value?.ga;
        }

        isExisting.value = true;
      }

      newIsSubLevel.value = !!values.subLevelGa;
    }

    function onClickEdit(item: Partial<Part>) {
      isNew.value = item.codeAmos ? false : true;
      item.ga = gaPart.value?.subLevelGa || gaPart.value?.ga || gaPart.value?.refManufacturer;
      copyFields(item, values);

      newIsSubLevel.value = !!values.subLevelGa;
      codeAmos.value = item.codeAmos || '';

      if (!item.gaItems) item.gaItems = {};
      values.quantity = item.gaItems[gaCode.value];

      showEditDialog.value = true;
    }

    const showFileDialog = ref(false);
    const currentFileParent = ref<Part>();
    const currentFileName = ref('');
    const fileValue = ref('');

    const rules = {
      codeAmos: [(v: string) => !!v || 'AMOS code is required'],
      subLevelGa: [
        (v: string) =>
          (newIsSubLevel.value && !isExisting.value && isNew.value && !!v) || 'GA ref is required',
      ],
    };

    async function onSubmitPart() {
      values.ga = gaCode.value;

      const body: PartSaveBody = { ...toRaw(values) };
      if (!newIsSubLevel.value) {
        delete body.subLevelGa;
        delete body.children;
      } else {
        body.children = partChildren.value.map((item) => item.codeAmos);
      }

      loading.value = true;
      try {
        await parts.save(body);
        await updateItems();
        showEditDialog.value = false;
      } finally {
        loading.value = false;
      }
    }

    function onEditFile(item: Part) {
      currentFileParent.value = item;
      if (item.documentation) {
        currentFileName.value = fileValue.value = `pdf/${item.documentation}`;
      } else {
        currentFileName.value = `pdf/${item.codeAmos}.pdf`;
        fileValue.value = '';
      }
      showFileDialog.value = true;
    }

    async function onFileChanged(fileName: string) {
      if (currentFileParent.value) {
        currentFileParent.value.documentation = fileName.replace('pdf/', '');

        const body = toRaw(currentFileParent.value);
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        delete body._id;

        await parts.save(body);

        const index = items.value.findIndex(
          (item) => currentFileParent.value?.codeAmos === item.codeAmos,
        );
        if (index >= 0) {
          items.value[index].documentation = fileName.replace('pdf/', '');
          // items.value.splice(index, 1, currentFileParent.value);
        }
      }
      showFileDialog.value = false;
    }

    /**
     * Delete stuff
     */
    const deleteItem = ref<Part>();
    const deleteShow = ref(false);

    function onDeleteItem(item: Part) {
      deleteItem.value = item;
      deleteShow.value = true;
    }

    async function onDeleteItemConfirm() {
      if (deleteItem.value) {
        const part = deleteItem.value;

        const index = items.value.findIndex((item) => item.codeAmos === part.codeAmos);
        await parts.delete(part.codeAmos, gaCode.value);
        if (index >= 0) items.value.splice(index, 1);
        deleteShow.value = false;
      }
    }

    /**
     * Cart logic
     */
    const addCartItem = ref<Part>();
    const addCartQty = ref(0);
    const showConfirmAddToCart = ref(false);

    function onAddToCart(item: Part) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      addCartQty.value = item.gaItems![gaCode.value] || 0;

      if (!cartStore.isInCart(item.codeAmos)) {
        cartStore.put(item, addCartQty.value);
      } else {
        addCartItem.value = item;
        showConfirmAddToCart.value = true;
      }
    }

    function onConfirmAddToCart() {
      if (addCartItem.value) cartStore.put(addCartItem.value, addCartQty.value);
      showConfirmAddToCart.value = false;
    }

    return {
      headers,
      gaCode,
      items,
      query,
      searchValue,
      filteredItems,
      cartStore,
      itemClass,

      showEditDialog,
      onClickEdit,

      isNew,
      isExisting,
      newIsSubLevel,
      codeAmos,
      searchExisting,
      existingItems,
      onChooseAmos,
      searchChild,
      childrenItems,
      partChildren,

      rules,
      formRef,
      values,
      validForm,
      loading,
      onSubmitPart,

      currentFileName,
      fileValue,
      showFileDialog,
      onEditFile,
      onFileChanged,

      deleteItem,
      deleteShow,
      onDeleteItem,
      onDeleteItemConfirm,

      mainStore,
      hasRoles: mainStore.hasRoles,

      onAddToCart,
      addCartItem,
      addCartQty,
      showConfirmAddToCart,
      onConfirmAddToCart,

      categories,
    };
  },
});
