

















import Vue from 'vue';
import { useCartStore } from '../cartStore';
export default Vue.extend({
  props: {
    item: {
      type: Object,
    },
  },

  setup() {
    const cartStore = useCartStore();
    return {
      cartStore,
    };
  },
});
