


































































import { defineComponent, ref, watch } from 'vue-demi';
import PartsTable from '../components/PartsTable.vue';
import { partsDb } from '../PartsDb';
import { useStore } from '@/store';
import FileUpload from '@/components/FileUpload.vue';
import RequireOnline from '@/components/RequireOnline.vue';
import SchematicsTable from '../components/SchematicsTable.vue';
import { Part } from '@project/shared';

export default defineComponent({
  components: { PartsTable, FileUpload, RequireOnline, SchematicsTable },

  setup(props, { root }) {
    const pdfRef = ref<HTMLIFrameElement | null>(null);
    const pdfUrl = ref('');
    const part = ref<Part>();
    const mainStore = useStore();

    const noSchematics = ref(false);

    const showFileDialog = ref(false);
    const currentFileName = ref('');
    const fileValue = ref('');

    async function loadPart() {
      pdfUrl.value = '';

      const { params } = root.$route;
      const item = (part.value = await partsDb.getByCodeAmos(params.codeAmos));

      if (item) {
        currentFileName.value = item.documentation
          ? `pdf/${item.documentation}`
          : `pdf/${item.refManufacturer} (${item.codeAmos}).pdf`;
        const file = await partsDb.getFile(currentFileName.value);
        if (file) {
          fileValue.value = currentFileName.value;
          pdfUrl.value = `/pdfjs/web/viewer.html#file=${URL.createObjectURL(file)}&name=${
            currentFileName.value
          }&zoom=page-width&pagemode=none`;
          return;
        }
      }
      pdfUrl.value = '';
    }

    watch(() => root.$route.params, loadPart, {
      immediate: true,
      deep: true,
    });

    function onEditFile() {
      if (!pdfUrl.value) {
        fileValue.value = '';
      }
      showFileDialog.value = true;
    }

    function onFileChanged() {
      showFileDialog.value = false;
      loadPart();
    }

    return {
      noSchematics,
      part,
      pdfUrl,
      hasRoles: mainStore.hasRoles,
      showFileDialog,
      currentFileName,
      fileValue,
      onEditFile,
      onFileChanged,
      pdfRef,
    };
  },
});
