























import Vue from 'vue';
import { computed } from 'vue-demi';
import { useStore } from '../../store';
export default Vue.extend({
  setup() {
    const mainStore = useStore();

    const hasRole = computed(() => {
      return mainStore.hasRoles(['admin']);
    });

    return {
      hasRole,
    };
  },
});
